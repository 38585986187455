<template>
    <div class="home">
      <Header/>
      <div class="content"> 
        <div class="title">{{$t('m.qykp')}}</div>    
    <div class="card">
      <div class="nft">
        <img src="../assets/img/nft.jpg" alt="" srcset="" class="ico">
      </div>
      <div class="line"></div>
      <div class="box">
      <div class="cont ">
         <div class="ibox ">          
          <div class="rbox">
            <div class="num">{{0}}</div>
            <div class="txt"></div>
          </div>
          <div class="txts">{{$t('m.grdhsl')}}</div>
         </div>     
        </div>
      </div>

    </div>
   <div class="card" >
    <div class="titles">{{$t('m.dh')}}</div>
    <div class="box">
        <div class="cont">
         <div class="ibox">         
          <div class="rbox">
            <div class="num">{{userInfo.cardAmount||0}}</div>
            <div class="txt">{{$t('m.zhang')}}</div>
            <!-- <img src="../assets/img/logo.png" alt="" srcset="" class="ico"> -->
          </div>
          <div class="txts">{{$t('m.wdkpsp')}}</div>       
         </div>
        </div>
    </div> 
    <div class="btngroup">
          <div class="btns" @click="toExchange">{{$t('m.dhnft')}}</div>
          <div class="btn" @click="toPage('/details')">{{$t('m.kpmx')}}</div>
        </div>
   </div>
   
    </div>
    <Bottom/>
    <van-popup v-model="lqshow" round :close-on-click-overlay="false">
      <div class="popup">
           <div class="top"> <div class="title">{{$t('m.dhnft')}}</div>
        <img src="../assets/img/gbs.png" alt="" class="close" v-if="!lqloading" @click="lqshow=false;lqloading=false"/>
    </div>
        <div class="sqtxt">{{$t('m.wdkpsp')}}</div>
          <div class="rbox">
            <div class="num">{{lqNum||0}}</div>
            <div class="txt">{{$t('m.zhang')}}</div>
          </div> 
         
          <div class="sinput">
            <div class="laber">{{$t('m.sl')}}</div>
            <input type="text" :placeholder="$t('m.srsls')" class="input" v-model="toNums" @input="maxs" :readonly="lqloading">
          </div>
          <div class="tips">{{$t('m.nfttips')}}</div>
        <div class="flsz"> 
         <van-button
          :loading="lqloading"
          type="primary"
          @click="toPay"
          class="btn"
        >
        {{$t('m.dh')}}</van-button
        >
        </div>
      </div>
    </van-popup>
</div>
</template>
<script>
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
import {getMyHistoryStatic,getMyRankUsers,toWithdrawalNode,toWithdrawalRank,getMyNodeCount,getMyNodePool,getMyRankPool,getUser} from  "@/api/Public";
export default {
  name: "myContracts",
  components: {Header,Bottom},
  data() {
    return {
        current:0,
        loading:false,
        dcshow:true,
        list:[],
        lists:[],
        lqNum:0,
        lqType:0,
        lqshow:false,
        lqloading:false,
        countNum:0,
        nodePool:0,
        rankPool:0,
        isNode:false,
        userWallet:"",
        userInfo:"",
        toAddress:"",
        page:1,
        toNum:""
    }
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },  
  },
  created(){
    // this.toGetList()
    this.userWallet=this.$store.getters.userWallet
    this.getInfo()
    // this.getUsers()
  },
  methods:{
    async getInfo(){
       let {data: { code, data, message },} = await this.$http.get(this.$api.getWallet, {
       trxAddress:"0xf56d9e51ceb014b6468ec7a00f5ee745eec204e5"|| this.$store.getters.userWallet,        
      });
      if(code==200){
        this.userInfo=data
        this.$store.commit("myID", data.id); 
      }
    },
    toExchange(){
      this.lqNum=this.userInfo.cardAmount
      this.lqshow=true
    },
    maxs(){

    },
    toPay(){

    },











    toLqShow(num,t){
      this.lqNum=num
      this.lqType=t
      this.lqshow=true
    },
    max(){
      setTimeout(() => {
        this.toNum=this.lqNum-this.toNum>0?this.toNum:this.lqNum
      }, 100);
    },
    async scSelect(){
    if(this.toNum<=0){return}
    if(!this.toAddress){return}
    this.lqloading=true
      transferNFTPoints(this.userWallet,this.toAddress, this.toNum).then(res=>{
        this.lqloading=false
        if(res){
            this.lqshow=false
            this.getUsers()
           this.$notify({
                // message: this.$i18n.t("m.lqsg") ,
                message:"转账成功",
                type: "success",
              });
        }
      })
    
    },
 
    getUsers(){
        getUser(this.$store.getters.userWallet).then(res=>{
          console.log(res);
        this.userInfo=Object.assign({},res)               
        // this.userInfo.luckyPoints=(res.luckyPoints/10**18).toFixed(4)
        // this.userInfo.nftPoints=(res.nftPoints/10**18).toFixed(4)         
      })
    },
    toPage(val) {    
       this.$router.push({ path: val });
    },
    changer(e){
        this.current=e
        this.getUsers()
        if(e==1){
        this.toGetLists()
        }
    },
    async toGetList(){
       let {data: { code, data, message },} = await this.$http.post(this.$api.getUserRegionList, {
       pageNum:this.page,  
       pageSize:10,
       uid:this.$store.getters.myID,
       rid:0

      });
      if(code==200){
        this.list=data
      }
    },
    toGetLists(){       
        getMyRankUsers().then(res=>{
            this.lists=res
            if(res){
              res.map(el=>{
               this.isNode=this.matchStringIgnoreCase(el.rankAddr,this.userWallet)
                              
              })
            }
        })
    },
    
  matchStringIgnoreCase(text, pattern) {
    const regex = new RegExp(pattern, 'i');
    return regex.test(text);
  }
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  // background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
    .content{
        margin-top: 188px;
        min-height: calc(100vh - 488px);
        .title{
      font-size: 38px;
      font-weight: bold;
      color: #ffffff;
      margin: 72px auto;
      border-radius: 36px 0 36px 0;
      width: 360px;
      padding: 22px;
      position: relative;
    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 16px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
    }
     .tab{
            width: 100%;
            border-bottom:1px solid #2A2A2A ;
            margin-top: 40px;
                padding-bottom: 20px;
          
            .item{
                flex: 1;
                color: #ffffff;
                padding-bottom: 20px;
                font-size: 28px !important;
            }
            .act{
                border-bottom: 1px solid #35dcf7;
            }
        }
        .card {
      // width: 694px;
      // background:#ffffff url("../assets/img/bgs.png") no-repeat ;
      background: #00000070;
         background-size: cover;
    background-position: left top;
          align-items: center;
          border: 1px solid #735f24;
      border-radius: 60px;
      padding: 30px 40px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .titles{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid #735f24;
      }
      .nft{
        width: 50%;
        .ico{
          width: 100%;
          height: 100%;
        }
      }
      .box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .tips{
           font-size: 24px;
           color: #ABABAB;
           margin-bottom: 40px;
          }
          .line{
            width: 100%;
            height: 1px ;
            background: #735f24;
            margin-top: 40px;
          }
        
        .cont{
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
          .left{
            font-size: 24px;
            color: #FFFFFF;
            opacity: 0.6;
          }
          .hflk{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .ibox{
              width: 48%;
            }
          }
          .xh{
            font-size: 24px;
            color: #FFFFFF;
            min-width: 70px;
            margin: 16px 0;
          }
          .address{
            font-size: 24px;
            color: #FFFFFF;
            min-width: 140px;
            max-width: 25%;
            margin: 16px 0;
            overflow: auto;
            white-space: nowrap;
          }
          .address::-webkit-scrollbar {
          display: none;
          }
          .right{
            .sinput{
              width:220px;
              border-radius: 100px;
              height: 64px;
              background: #2A2A2A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .txt{
                color: #717171;
                margin-right: 16px;
              }
            }
          }
          .input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #2A2A2A;
            border-radius: 100px;
            height: 64px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px 0;       
            .yinput{
              border: none;
              background: transparent;
              color: #ffffff;
              font-size: 28px;
              font-weight: bold;
            }
            .dw{
              display: flex;
              align-items: center;
              .tt{
                color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
              }
              .ico{
                width: 40px;
                height: 40px;
              }
            }
          }
          .icos{
            font-size: 36px;
            color: #ffffff;
          }
          .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
            .num{
          //  margin: 22px auto;
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
        background: linear-gradient(100deg, #d5b275 0%, #e6d69a 31%,#d9ad2a 49%, #c5a26c 79%, #a58b00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #ffffff;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }

         
          .ibox{
            border: 1px solid #c59f30 ;
            box-sizing: border-box;
            background: linear-gradient(135.46deg, #3b3b3b 24.8%, #000000 57.07%);
            box-shadow: inset 3px 3px 4px 0px #000000, inset -3px -3px 4px 0px #616161; 
            border-radius: 40px;
            width: 100%;
            margin-top: 40px;
            padding: 30px 40px;
            box-sizing: border-box;
            .txts{
              color: #ffffff;
              font-size: 28px;
              margin-bottom: 20px;
            }
          }
          .hbg{
            background: #0e0e0e;
            border: none;
          }
          .btnr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .btn{
          background: #2A2A2A;
            color: #ABABAB;
            border: none;
            border-radius: 100px;
            height: 68px;
            width: 250px;
            font-size: 24px;
          }
        .act{
          color: #ffffff;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
          // background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
        }
          }
        

        }
      }
      .btngroup{
            display: flex;
            align-items: center;
            margin-top: 40px;
            border-top: 1px solid #4e4119;
            padding-top: 40px;
            width: 100%;
            .btns{
              background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 68px;
              line-height: 68px;
              flex:1;
              border: 1px solid #906c37;
            }
            .btn{
              background: transparent;
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 68px;
              line-height: 68px;              
              flex:1;
              border: 1px solid #906c37;
              margin-left: 20px;
            }
          }
      .buttom{
        display: flex;
        flex-direction: column;
        width: 100%;    
        margin: 40px auto 30px;  
        .ubtom{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .btn{
            width: 45%;
          }
        }
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
        }
        .act{
          color: #ffffff;
          background: linear-gradient(100deg, #dcc179 0, #603711 49%, #d5b96f 100%);
          // background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
        }
        .tip{
            color: #ABABAB;
            font-size: 20px;
            margin-top: 40px;
            // white-space: nowrap;
        }
      }
        
       

       .imggrop{
          display: flex;
          align-items: center;
          justify-content: center;
          .im1{
            width: 144px;
            height: 144px;
            z-index: 2;
            
          }.im2{
             width: 144px;
            height: 144px;
            margin-left: -60px;
            z-index: 1;
          }
        }
        .ttx{
          font-size: 36px;
          font-family: $t-f;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
      
        
      .sbot {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        text-align: center;
        margin: 24px 0;
          .txt {
            height: 56px;
            font-size: 46px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            font-size: 56px;
            color: #ffffff;
          }
          .btn {
            background: #ffffff;
            // box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
            // border-radius: 40px;
            font-size: 32px;
            font-family: $t-f;
            font-weight: 600;
            color: #000000;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 280px;
height: 80px;
          }
      }
      .title{
        font-size: 36px;
font-family: $t-f;
font-weight: 600;
color: #000000;
line-height: 50px;
text-align: left;
      }
       .lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        width: 92%;
        text-align: center;
        margin: 37px auto;
        .item {
          text-align: center;
          flex: 1;
          
          .txt1 {
            height: 54px;
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }
          .txt2 {
            height: 40px;
            font-size: 28px;
            font-family: $t-mf;
            font-weight: 600;
            color: #888888;
            line-height: 40px;
            text-align: center;
            margin-top: 18px;
          }
        }
      }
    }
       
    }
   .mlist{
    margin-top: 80px;
    .stitle{
        font-size: 32px;
        color: #ffffff;
        text-align: center;
    }
    .list{
        height: 50vh;
    }
   }

    

  .popup {
    width: 672px;
    background: #ffffff;
    // border-radius: 30px;
        padding: 30px 30px 40px;
        box-sizing: border-box;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    ::v-deep .van-stepper__input{
          font-size: 42px;
        }
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .close{
                width:28px;
            }
             .title{
            width: 100%;
            font-size:32px;
            font-weight: bold;
            color: #000000;
            text-align: left;
           
        }
        }
        .sqtxts{
      font-size: 24px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
      text-align: left;
    }
    .sqtxt{
      font-size: 26px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 15px;
      padding: 0 22px;
      box-sizing: border-box;
      .ico{
        width: auto;
        height: 200px;
      }
    }
       .rbox{
            display: flex;
            align-items: flex-end;
            justify-content: center;
           margin: 20px 0 40px;
            .num{
          //  margin: 22px auto;
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(234deg, #ff8504, #cc1d1d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #2A2A2A;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }
          .tips{
            color: #a7841e;
                font-size: 28px;
                font-weight: bold;          
                white-space: nowrap;
                margin-bottom: 40px;
          }
          .sinput{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 90%;
            background: #efefef;
            border-radius: 10px;
            height: 84px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px auto; 
            .laber{
              color: #a7841e;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
                padding-right: 16px;
                border-right: 1px solid #a7841e;
            }      
            .input{
              border: none;
              background: transparent;
              color: #a7841e;
              font-size: 28px;
              font-weight: bold;
              flex: 1;
            }
           
          }
          .flsz{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

            .btn {
            width: calc(100% - 200px);
              height: 80px;
              font-size: 32px;
              font-family: $t-mf;
              font-weight: 600;
              color: #ffffff;
              line-height: 80px;
              margin: 24px auto 34px;
        background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
        box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
        border-radius: 40px;
        border: none;
          display: flex;
              align-items: center;
              justify-content: center;
            }
    }
}

  </style>
